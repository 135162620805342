import Routing from './Routing/Router'
import './main.css'
import './App.css'

function App() {
  return (
    <div className=" App bg-bgColor">
      <Routing/>
    </div>
  );
}

export default App;
